




















import Vue from 'vue';
import {Component} from "vue-property-decorator";

@Component({
    name: "App",
})
export default class App extends Vue {
  showBackButton(): boolean {
    // 현재 라우트가 '/login'이 아닌 경우에만 true 반환
    return this.$route.path !== '/login';
  }
}
