import axios from "@/utils/axios"
import {AxiosResponse} from "axios";

/**
 * 토큰 발급
 * @param code 베타 액세스 코드
 *
 */
function generateToken(code: string): Promise<AxiosResponse> {
    return axios({
        url: "/api/beta/token",
        method: "get",
        params: {
            code: code
        },
        withCredentials: true
    });
}

function sendMessageInChat(message: string): Promise<AxiosResponse> {
    return axios({
        url: "/api/v1/whoright",
        method: "post",
        data: {
            text: message
        },
        withCredentials: true
    });
}

function sendMessageSummary(message: string): Promise<AxiosResponse> {
    return axios({
        url: "/api/v1/whoright/summary",
        method: "post",
        data: {
            text: message
        },
        withCredentials: true
    });
}

function sendMessageJudge(message: string): Promise<AxiosResponse> {
    return axios({
        url: "/api/v1/whoright/judgement",
        method: "post",
        data: {
            text: message
        },
        withCredentials: true
    });
}


export {
    generateToken,
    sendMessageInChat,
    sendMessageSummary,
    sendMessageJudge
}