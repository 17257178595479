import {NavigationGuardNext, Route, RouteConfig} from "vue-router";
import ChatForm from "@/views/form/ChatForm.vue";
import DeepChatForm from "@/views/form/DeepChatForm.vue";
import ResultForm from "@/views/form/ResultForm.vue";

export const namespaced = true;
const chatRoutes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Chat",
        component: ChatForm,
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            next();
        }
    }
];

const deepChatRoutes: Array<RouteConfig> = [
    {
        path: "/deepchat",
        name: "DeepChat",
        component: DeepChatForm,
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            next();
        }
    }
];

const resultRoutes: Array<RouteConfig> = [
    {
        path: "/result",
        name: "Result",
        component: ResultForm,
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            next();
        }
    }
];


export { chatRoutes, deepChatRoutes, resultRoutes }