
























































































































import {Component, Ref} from "vue-property-decorator";
import Vue from "vue";
import SnackBar from "@/components/SnackBar.vue"
import {error, confirm, message} from "@/constant";
import {createResource} from "@/api";
import {IResource} from "@/type/IResource";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {IUpload} from "@/type/IUpload";
import {getPresignedUrl, upload} from "@/api/example";
import {AxiosResponse} from "axios";
import {CommonResponse} from "@/type/response";
import {sendMessageInChat} from "@/api/beta";
import '@/assets/common.css';

@Component({
  name: "ChatForm",
  components: {
    SnackBar,
    ConfirmDialog
  }
})
export default class ChatForm extends Vue {
  // 폼 설정
  resourceValidate = true;

  // 확인 메세지
  confirm = confirm;

  // 리소스 이름
  resourceName = "";

  // 업로드 정보
  uploads: Array<IUpload> = [];
  file!: File;

  // Loading
  loadingchk = false;

  // 레퍼런스 초기화
  @Ref() readonly snackbar!: SnackBar;
  @Ref() readonly resourceForm!: HTMLFormElement;
  @Ref() readonly confirmDialog!: ConfirmDialog;

  /**
   * 입력값 클리어
   */
  clear(): void {
    this.resourceName = "";
  }

  messages = [
    {sender: '상대방', text: '안녕하세요! 반가워요!'}
  ];
  // 새로운 메시지 입력값
  newMessage = '';

  imageFile = null;

  headlineText = '후라이에게 무엇이든 물어보세요';


  sendMessage() {
    if (this.newMessage.trim() !== '') {
      this.messages.push({
        sender: 'user',
        text: this.newMessage.trim()
      });

      this.loadingchk = true;

      sendMessageInChat(this.newMessage.trim()).then((res: AxiosResponse) => {
        console.log(res.data)

        this.$router.push({name: 'Result', params: {data: res.data.data.data}});
        // TODO: 타입 정의
        // this.messages.push({sender: '상대방', text: res.data.data.data})

        this.loadingchk = false;
      })
      this.newMessage = '';
    }
  }

  // uploadImage(): void {
  //   //이미지 s3업로드
  //   getPresignedUrl("whoright-image", this.imageFile.name + this.imageFile.lastModified, this.imageFile.type).then((res) => {
  //     const presignedUrl = res.data;
  //     console.log("Presigned Url => " + presignedUrl);
  //
  //     upload(presignedUrl, this.file).then((res) => {
  //       console.log("업로드 성공")
  //       console.log(res)
  //
  //     }).catch((err) => {
  //       console.error("업로드 에러")
  //       console.error(err)
  //     })
  //   })
  //   //api 호출
  // }

  // uploadImageS3(): void{
  //
  // }


}
