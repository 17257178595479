import Vue from 'vue'
import VueRouter, {NavigationGuardNext, Route, RouteConfig} from 'vue-router'
import {chatRoutes, deepChatRoutes, resultRoutes} from "@/router/chat";
import {authRoutes} from "@/router/auth";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [];

// 예제 라우트 정보 추가
routes.push(...chatRoutes);
routes.push(...authRoutes)
routes.push(...deepChatRoutes)
routes.push(...resultRoutes)

// 예외 라우트 설정
routes.push( {
    path: "*",
    redirect: "/"
})

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

// 라우트 가드 설정
router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    const token = Vue.$cookies.get(process.env.VUE_APP_ACCESS_TOKEN_KEY_NAME);

    // if (token || to.path == "/login") {
    // next();
    // } else {
    //     next("/login")
    // }
    next();
})

export default router
