



































































import {Component, Ref } from "vue-property-decorator";
import SnackBar from "@/components/SnackBar.vue"
import {error} from "@/constant";
import {getResource} from "@/api";
import {IResourceDetail} from "@/type/IResourceDetail";
import Vue from "vue";


@Component({
    name: "ResourceDetail",
    components: {
        SnackBar
    }
})
export default class ResourceDetail extends Vue {
    // 리소스 ID
    id = 0;

    // 리소스 이름
    resourceName = "";

    // UUID
    uuid = "";

    // 생성일
    createdDate = "";

    // 레퍼런스 초기화
    @Ref() readonly snackbar!: SnackBar;

    /**
     * 입력값 클리어
     */
    clear(): void {
        this.id = 0;
        this.resourceName = "";
        this.uuid = "";
    }

    /**
     * 리소스 생성
     * @param id 리소스 ID
     */
    getResource(id: number): void {
        getResource(id).then((res) => {
            // 리소스 정보 설정
            const resourceDetail: IResourceDetail = res.data;

            this.resourceName = resourceDetail.resourceName;
            this.id = resourceDetail.id;
            this.uuid = resourceDetail.uuid;
            this.createdDate = resourceDetail.createdDate;
        }).catch((err) => {
            console.error(err);
            this.snackbar.onSnackBar(error.ERR_GET_RESOURCE);
        })
    }
}
