
















































































































import {Component} from "vue-property-decorator";
import Vue from "vue";
import SnackBar from "@/components/SnackBar.vue"
import ResourceDetail from "@/views/dialog/ResourceDetail.vue"

@Component({
    name: "Login",
    components: {
        SnackBar,
        ResourceDetail
    }
})
export default class Login extends Vue {
  isAndroid = false;

  created() {
    this.detectMobileOS();
  }

  /**
   * 기기 OS 감지
   */
  detectMobileOS(): void {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (userAgent.includes("KAKAOTALK") && userAgent.includes("Android")) {
      this.isAndroid = true;
    }
  }

    /**
     * 구글 로그인
     */
    googleLogin(): void {
        const loginUrl = "https://accounts.google.com/o/oauth2/v2/auth?"
            + "scope=https://www.googleapis.com/auth/userinfo.profile"
            + "&access_type=offline"
            + "&include_granted_scopes=true"
            + "&response_type=code"
            + "&state=test"
            + "&client_id=" + process.env.VUE_APP_GOOGLE_CLIENT_ID
            + "&redirect_uri="+ process.env.VUE_APP_GOOGLE_CALLBACK_URL;

        window.location.href = loginUrl;
    }

    /**
     * 카카오 로그인
     */
    kakaoLogin(): void {
        const loginUrl = "https://kauth.kakao.com/oauth/authorize?"
            + "response_type=code"
            + "&client_id=" + process.env.VUE_APP_KAKAO_CLIENT_ID
            + "&redirect_uri="+ process.env.VUE_APP_KAKAO_CALLBACK_URL
            + "&state=test";

        window.location.href = loginUrl;
    }
}
