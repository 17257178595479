import axios from "@/utils/axios"
import {IResource} from "@/type/IResource";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {IUpload} from "@/type/IUpload";
import {ITest} from "@/type/ITest";

/**
 * 리소스 목록 조회
 * @param offset 오프셋
 * @param limit 리피트
 */
function getResources(offset = 0, limit = 10): Promise<AxiosResponse> {
    return axios({
        url: "/api/resources",
        method: "get",
        params: {
            offset: offset,
            limit: limit
        },
        withCredentials: true
    });
}

/**
 * 리소스 상세 정보 조회
 * @param id 리소스 ID
 */
function getResource(id: number): Promise<AxiosResponse> {
    return axios({
        url: "/api/resources/" + id,
        method: "get",
        withCredentials: true
    });
}

/**
 * 리소스 등록
 * @param resource 리소스 정보
 */
function createResource(resource: IResource): Promise<AxiosResponse> {
    return axios({
        url: "/api/resources/resource",
        method: "post",
        data: resource,
        withCredentials: true
    });
}

/**
 * 리소스 변경
 * @param id 리소스 ID
 * @param resource 리소스 정보
 */
function updateResource(id: number, resource: IResource): Promise<AxiosResponse> {
    return axios({
        url: "/api/resources/" + id,
        method: "put",
        data: resource,
        withCredentials: true
    });
}

function getPresignedUrl(bucket:string, fileName:string, fileType: string): Promise<AxiosResponse> {
    return axios({
        url: "/api/v1/presigned-url",
        params: {
            bucket: bucket,
            fileName: fileName,
            fileType: fileType
        },
        method: "get",
        withCredentials: true
    })
}

function upload(presignedUrl: string, file: File): Promise<AxiosResponse> {
    return axios({
        url: presignedUrl,
        method: "put",
        data: file,
        headers: {

            'Content-Type': file.type
        },
        withCredentials: false
    })
}

function test(test: ITest): Promise<AxiosResponse> {
    return axios({
        url: "http://localhost:8081/api/test",
        method: "put",
        data: test,
        withCredentials: false
    })
}

export {
    getResources,
    getResource,
    createResource,
    updateResource,
    getPresignedUrl,
    upload
}