






















































import {Component, Ref} from "vue-property-decorator";
import Vue from "vue";
import SnackBar from "@/components/SnackBar.vue"
import {error, confirm, message} from "@/constant";
import {createResource} from "@/api";
import {IResource} from "@/type/IResource";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {IUpload} from "@/type/IUpload";
import {getPresignedUrl, upload} from "@/api/example";
import {AxiosResponse} from "axios";
import {CommonResponse} from "@/type/response";
import {sendMessageInChat, sendMessageSummary, sendMessageJudge} from "@/api/beta";

@Component({
    name: "DeepChatForm",
    components: {
        SnackBar,
        ConfirmDialog
    }
})
export default class DeepChatForm extends Vue {
    // 폼 설정
    resourceValidate = true;

    // 확인 메세지
    confirm = confirm;

    // 리소스 이름
    resourceName = "";

    // 업로드 정보
    uploads: Array<IUpload> = [];
    file!: File;

    // Loading
    loadingchk = false;

    // judgement
    judgement = false;

    // pasteMessage
    pasteMsg = '';

    // 레퍼런스 초기화
    @Ref() readonly snackbar!: SnackBar;
    @Ref() readonly resourceForm!: HTMLFormElement;
    @Ref() readonly confirmDialog!: ConfirmDialog;

    /**
     * 입력값 클리어
     */
    clear(): void {
        this.resourceName = "";
    }

  messages= [
    { sender: '상대방', text: '안녕하세요! 반가워요!' }
  ];
  // 새로운 메시지 입력값
  newMessage=  '';
  imageFile= null;
  moreInfoText = 'More Information:';

  sendMessage() {
    if (this.newMessage.trim() !== '') {
      this.messages.push({
        sender: 'user',
        text: this.newMessage.trim()
      });


      // loading
      this.loadingchk = true;
      sendMessageSummary(this.newMessage.trim()).then((res: AxiosResponse) => {

        if (res.data.data.data) {
          console.log(res.data.data.data)
          this.$router.push({ name: 'SummaryChat', params: { data: res.data.data.data } });
        }

        this.newMessage = ''
        this.loadingchk = false;
      })
    }
  }

  uploadImage():void{
    //이미지 s3업로드
    console.log(this.imageFile);
    getPresignedUrl("whoright-image",this.file.name+this.file.lastModified, this.file.type).then((res) => {
      const presignedUrl = res.data;
      console.log("Presigned Url => " + presignedUrl);

      //s3
      upload(presignedUrl, this.file).then((res) => {
        console.log("업로드 성공")
        console.log(res)
      }).catch((err) => {
        console.error("업로드 에러")
        console.error(err)
      })
    })
    //api 호출
  }

  // uploadImageS3(): void{
  //
  // }



}
