














































import {Component} from "vue-property-decorator";
import Vue from "vue";

@Component({
    name: "ConfirmDialog"
})
export default class ConfirmDialog extends Vue {
    confirmDialog = false;
    confirmDialogText = "";
    confirmEvent: any = () => { return null };

    /**
     * 확인창
     * @param text 확인창 텍스트
     * @param event 이벤트
     */
    onConfirmDialog(text: string, event?: any): void {
        this.confirmDialogText = text;
        this.confirmDialog = !this.confirmDialog;

        // 이벤트 파라미터가 존재하는 경우 호출
        if (event != null) {
            this.confirmEvent = event;
        }
    }

    /**
     * 확인창 다이얼로그 토글
     */
    toggleDialog(): void {
        this.confirmDialog = !this.confirmDialog;
    }
}
