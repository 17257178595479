


























































































import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import 'vue-kakao-sdk';
import "@/assets/common.css"
import {IWhoRightResult} from "@/type/IWhoRightResult";
import html2canvas from "html2canvas";

@Component
export default class ResultForm extends Vue {
  @Prop() data!: any;

  resultAdvice = '';
  resultEvaluation = '';

  get receivedData(): IWhoRightResult {
    let parsedData: IWhoRightResult;
    if (typeof this.$route.params.data === 'string') {
      try {
        parsedData = JSON.parse(this.$route.params.data) as IWhoRightResult;
      } catch (error) {
        console.error('Failed to parse data:', error);
        // JSON 파싱 실패 시 기본값 설정
        parsedData = {
          assistant_name: '',
          characters: [],
          evaluations: [],
          mistakes_score: [],
          advice: '',
          overall_evaluation: ''
        };
      }
    } else {
      // `data`가 문자열이 아닐 경우 직접 타입 단언
      parsedData = this.$route.params.data as IWhoRightResult;
    }

    // `resultAdvice` 변수에 `advice` 값 설정
    this.resultAdvice = parsedData.advice;
    this.resultEvaluation = parsedData.overall_evaluation;

    return parsedData;
  }

  copyToClipboard(): void{
    console.log(window.Kakao);
    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: this.resultEvaluation,
        description: this.resultAdvice,
        imageUrl: 'https://whoright.ai/img/logo.dc4ebb70.png',
        link: {
          webUrl: 'https://whoright.ai'
        }
      },
      buttons: [
        {
          title: '웹으로 보기',
          link: {
            webUrl: 'https://whoright.ai'
          }
        }
      ]
    });


  }

  downloadImage(): void {
    const captureArea = this.$refs.captureArea as HTMLElement | null;

    if (captureArea) {
      html2canvas(captureArea)
        .then(canvas => {
          const image = canvas.toDataURL('image/png');

          // download
          const now = new Date();
          const nowDate = `${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
          const link = document.createElement('a');
          link.href = image;
          link.download = `Whoright-ScreenShot-${nowDate}.png`;
          link.click();
        })
        .catch(error => {
          console.error('Failed to capture the screen:', error);
        });
    } else {
      console.error('captureArea is null.');
    }
  }

  mounted() {
    // 새로고침 시 `this.$route.params.data`가 없으면 리다이렉트
    if (!this.$route.params.data) {
      this.$router.replace('/chat');
    }
  }
}
