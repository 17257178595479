import {NavigationGuardNext, Route, RouteConfig} from "vue-router";
import Login from "@/views/auth/Login.vue";

export const namespaced = true;
const authRoutes: Array<RouteConfig> = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
            next();
        }
    }
];

export { authRoutes }